<template>
  <div id="news">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="newsCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p v-if="newsData.title&&newsData.title.length>0" class="animated bounceInLeft" v-html="newsData.title[0].title"></p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>
      <!--新闻时间线-->
      <div class="timeLines timeLineNews" >
        <div class="timeLineCon">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in yearListNews" :key="index">
                <div class="timeItems" :class="{timeActive:queryInfo.year==item}" @click="changeTime(item)">
                  <h3>{{ item }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="border-bottom: 0.2777vw solid #20BFD0;width: 100vw;margin-top:-4.166vw;float:left;"></div>
      </div>

      <!--公司动态-->
      <div class="news_gsDynamic">
        <!--公司动态内同-->
        <div class="title_newsBox" v-show="newsList&&newsList.length>0">
          <div class="newsItem" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)">
            <div class="newsData">
              <span class="newsDay_m">{{item.date.substr(5,2)}}月</span>
              <span class="newsDay_d">{{item.date.substr(8,2)}}日</span>
            </div>
            <div class="newsTitle">{{item.title}}</div>
            <div class="newsCons">
              {{item.desc}}
            </div>
          </div>
        </div>
        <div class="title_newsBox" v-show="newsList&&newsList.length==0">
          <div class="newsItem" >
            <div class="newsTitle">已经没有更多动态啦！</div>
          </div>
        </div>

        <div class="nextPage" v-if="newsList.length>0" @click="toNextPage">
          <span></span>
        </div>
      </div>

      <!--footer-->
      <div v-if="newsList&&newsList.length>0">
        <Copyright></Copyright>
      </div>
      <div class="footers" v-else>
        <Copyright></Copyright>
      </div>
    </div>

  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import Swiper from 'swiper';
import {GetYear,GetNewsData,GetIndexDoc} from "@/api/api";

export default {
  data(){
    return{
      //新闻动态banner
      newsData:{},
      //新闻年份
      yearListNews: [2022,2021,2020,2019,2018,2017],
      // yearListNews: [],
     //新闻动态当前年份
      newsList: [],
      //请求数据参数+
      queryInfo:{
        year:2022,
        type:1,
        page:1
      }

    }
  },
  mounted() {
    //获取年份数据
    this.toGetYear()
    //获取新闻动态信息
    this.toGetNewsData()
    //获取新闻动态页面banner数据
    this.toGetIndexDoc(8)
    new Swiper('.newsCon .timeLineNews .mySwiper',{
      slidesPerView: 5,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    })
  },
  methods:{
    //获取年份
    toGetYear(){
      let _this=this
      GetYear()
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.yearListNews=[]
              return
            }
            let apiIndex=res.data
            _this.yearListNews=apiIndex.news
            //发展历程当前年份
            _this.queryInfo.year=apiIndex.news[0]
          })
    },
    //获取新闻动态页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.newsData=[]
              return
            }
            let apiIndex=res.data
            _this.newsData=apiIndex
          })
    },
    //获取新闻动态信息
    toGetNewsData(){
      let _this=this
      GetNewsData(this.queryInfo)
        .then(r=>{
          let res=r.data
          if(res.status!=1){
            _this.newsList=[]
            return
          }
          let apiNewsData=res.data
          _this.newsList=apiNewsData
        })

    },
    changeTime(data){
      this.queryInfo.year=data
      this.queryInfo.page=1
      this.toGetNewsData()
    },
    toNextPage(){
      this.queryInfo.page++
      this.toGetNewsData()
    },
    //  跳转至详情页
    toDetail(item){
      this.$router.push({path:'/index/doc/newsDocDetail',query:{id:item}})
    }
  },
  components: {
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
#news {
  background-color: #071D42;
  min-height: 100vh;
}
.newsCon {
  position: relative;
  padding-top: 13.888vw;
  background-color: #071D42;
  width: 100%;

  .commenHead {
    background: url("../assets/images/pic_banner_news.png") no-repeat right top;
    background-size: 120%;
  }

  .news_gsDynamic{
    margin:8.333vw 6.666vw 16.666vw;
    .title_news{
      font-size:4.166vw;
      line-height: 5.8333vw;
      color: #20BFD0;
      a{
        display: inline-block;
        margin-left: 2.2222vw;
        width: 4.1666vw;
        height: 4.4444vw;
        background: url("../assets/images/ico_arrow_pixel2.png") no-repeat;
        background-size: 100%;
        vertical-align: middle;
      }
    }
    .title_newsBox{
      margin-top:3.333vw;
      color: #20BFD0;
      .newsItem{
        padding-bottom: 3.333vw;
        margin-bottom: 3.333vw;
        border-bottom: 0.2777vw solid rgba(255,255,255,.3);
        text-align: justify;
        .newsData{
          font-size:2.5vw;
          line-height: 2.5vw;
          font-weight: 600;
          margin-bottom: 1.666vw;
          .newsDay_m{
            color:#071D42;
            padding:0 1.1111vw;
            border-radius: 0.5555vw;
            background-color:#20BFD0;
          }
          .newsDay_d{
            margin-left: 1.1111vw;
          }
        }
        .newsTitle{
          font-size:3.333vw;
          line-height: 4.722vw;
          font-weight: 400;
          margin-bottom: 1.666vw;
        }
        .newsCons{
          font-size: 2.777vw;
          line-height: 4.444vw;
          color:#bbbc;
        }
      }
    }
    .nextPage{
      margin-left: 50%;
      span{
        display: inline-block;
        width: 4.166vw;
        height: 4.444vw;
        margin:1.1111vw;
        margin-left: -0.5555vw;
        background: url("../assets/images/ico_arrow_pixel2.png") no-repeat;
        background-size: 100%;
        transform: translateX(-50%) rotate(90deg);
      }
    }
  }
}
.footers {
  width: 100%;
  position:fixed;
  bottom:0
}
</style>